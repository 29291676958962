.editAgeTypes {
  .title {
    margin-bottom: 10px;
  }

  .fullWidth {
    width: 100%;
  }

  .loader {
    margin-left: 50%;
    position: relative;
  }
}
