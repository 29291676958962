@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.image_resize_button_wrappers {
  display: flex;
  justify-content: end;
  margin-top: 14px;
  gap: 16px;
  @include susy-media($smartphone) {
    text-align: right;
    display: block;
  }
}

.image_resize_button_wrappers button {
  @include susy-media($smartphone) {
    width: 100%;
  }
}

.image_resize_button_wrappers > button + button {
  @include susy-media($smartphone) {
    margin-top: 16px;
  }
}
