@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.filterOpdForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1fr !important;
  .MuiAccordion-root {
    width: 100%;
  }
  .MuiAccordionSummary-content {
    svg {
      position: relative;
      top: -2px;
    }
    h5 {
      margin: 0px 10px;
    }
  }
  .filterOpdForm__form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 8px;
    .filterOpdForm__section {
      display: flex;
      flex-direction: column;
      width: 100%;
      .filterOpdForm__section_title {
        text-transform: uppercase;
        color: $c-grey-lighter;
        font-size: medium;
      }
      .filterOpdForm__section_content {
        display: flex;
        flex-flow: wrap row;
        justify-content: space-between;

        .filterOpdForm__item {
          margin-right: 2px;
          align-items: center;
          width: 100%;
          .dateField,
          .dateField__light,
          .textField,
          .selectField,
          .autocompleteField {
            width: 100%;
          }
          @include susy-media($large) {
            width: 24%;
          }
          @include susy-media($medium-up) {
            width: 30%;
          }
        }
        .fullWidth {
          width: 100%;
        }
        .halfWidth {
          width: 49%;
        }
      }
    }
    .filterForm__buttonSet {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      gap: 8px;

      .MuiButton-root {
        width: 16%;
        @include susy-media($tablet_land) {
          width: 26%;
        }
        @include susy-media($phone) {
          width: 50%;
        }
      }
    }
  }
  @media print {
    display: none !important;
  }
}
