@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.labs {
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: $c-white;

  .labs__background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .labs__content {
    margin-top: 20px;
    width: 80%;
    @include susy-media($narrow) {
      width: 90%;
    }
    @include susy-media($tablet_land) {
      width: 90%;
    }
    @include susy-media($tablet_port) {
      width: 90%;
    }
    @include susy-media($smartphone) {
      width: 90%;
    }
  }

  .footer {
    position: relative;
    margin-top: auto;
  }
}
