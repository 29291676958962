@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.currentAdmission {
  flex-direction: column;
  display: flex;
  row-gap: 4px;
  padding: 4px 16px;
  margin-bottom: 16px;
  border-radius: 5px;
  .currentAdmissionData {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    row-gap: 4px;
    background-color: rgba($c-grey-light, 0.2);
    .currentAdmission_leading {
      display: flex;
      justify-content: end;
    }
    .currentAdmissionData__content {
      display: flex;
      flex-wrap: wrap;
      column-gap: 4px;
      .currentAdmissionData__item {
        display: flex;
        flex-direction: column;
        justify-content: start;
        flex-grow: 0;
        width: 48%;
        @include susy-media($smartphone) {
          width: 100%;
        }

        &.fullWidth {
          width: 100%;
        }

        .item_content {
          word-break: break-word;
        }
      }
      .item_label {
        font-size: 0.85em;
        font-weight: 600;
      }
    }
  }
  .currentAdmissionForm {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    .currentAdmissionForm__item {
      margin: 7px 0px;
      padding: 0px 15px;
      width: 50%;
      @include susy-media($narrow) {
        padding: 0px 10px;
      }
      @include susy-media($tablet_land) {
        padding: 0px 10px;
      }
      @include susy-media($tablet_port) {
        width: 50%;
      }
      @include susy-media($smartphone) {
        width: 100%;
      }
      .dateField,
      .textField,
      .selectField {
        width: 100%;
      }

      &.fullWidth {
        width: 100%;
      }

      &.halfWidth {
        width: 50%;
        @include susy-media($smartphone) {
          width: 100%;
        }
      }

      &.compressed {
        .textField {
          float: left;
          width: 50%;
          &:nth-of-type(1) {
            padding-right: 10px;
          }
        }
      }
    }
    .currentAdmissionForm__buttonSet {
      display: flex;
      margin-top: 10px;
      flex-direction: row-reverse;
      justify-content: end;

      .submit_button {
        .MuiButton-label {
          font-size: smaller;
          letter-spacing: 1px;
          font-weight: 600;
        }
        button {
          @include susy-media($smartphone_small) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
