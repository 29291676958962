@import "../../../../../../../../node_modules/susy/sass/susy";
@import "../../../../../../../styles/variables";

.ageTypesForm {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;

  .row {
    justify-content: space-between;
  }

  table.ageTypesFormTable {
    min-width: 100%;
    overflow-x: auto;
    border-collapse: collapse;

    th {
      padding: 10px 0;
      text-align: left;
      border-bottom: solid 2px #999;
    }
    td {
      border-bottom: solid 1px #999;
    }

    .fromField,
    .toField {
      width: 175px;
      padding: 1px 6px;

      .textField {
        width: 175px;
      }
    }
  }

  td.empty {
    span {
      padding: 5px 13px;
      font-weight: bold;
      font-size: 19px;
      color: #555;
    }
  }

  .ageTypesForm__buttonSet {
    display: flex;
    margin-top: 25px;
    padding: 0px 15px;
    flex-direction: row-reverse;
    @include susy-media($smartphone_small) {
      display: block;
    }

    .submit_button,
    .reset_button {
      .MuiButton-label {
        font-size: smaller;
        letter-spacing: 1px;
        font-weight: 600;
      }
      button {
        @include susy-media($smartphone_small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
