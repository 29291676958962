@import "../../../../../styles/variables";
@import "../../../../../../node_modules/susy/sass/susy";

.examForm {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .formInsertMode {
    margin: 0px 0px 20px;
  }

  .row {
    justify-content: space-between;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .exam-rows {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    width: 70%;
    align-items: center;
    &.procedure-2 {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
      &.procedure-2 {
        width: 100%;
      }
    }

    .col-start-1 {
      grid-column-start: 1;
    }

    .row {
      display: flex;
      gap: 4px;
    }

    .radio {
      justify-self: center;
      grid-column-start: 3;
    }
    .error {
      color: red;
      font-size: x-small;
      align-self: flex-start;
      justify-self: start;
      grid-column-start: 1;
      grid-column: span 3;
      margin: 7px 0px;
      padding: 0px 15px;
    }
    .title {
      font-size: large;
      margin: 7px 0px;
      padding: 0px 15px;
    }
    .trailing {
      margin: 7px 0px;
      padding: 0px 15px;
      grid-column-start: 3;
    }
    .add-row-icon {
      margin-left: 16px;
      width: 40px;
      height: 40px;
      grid-column-start: 1;
    }
  }

  .examForm__item {
    margin: 7px 0px;
    padding: 0px 15px;
    width: 50%;
    @include susy-media($narrow) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_land) {
      padding: 0px 10px;
    }
    @include susy-media($medium-up) {
      width: 25%;
    }
    @include susy-media($tablet_port) {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
    .textField,
    .selectField {
      width: 100%;
    }

    &.fullWidth {
      width: 100%;
    }

    &.halfWidth {
      width: 50%;
      @include susy-media($smartphone) {
        width: 100%;
      }
    }
    &.thirdWidth {
      width: 33%;
      @include susy-media($smartphone) {
        width: 100%;
      }
    }
  }

  .examForm__buttonSet {
    display: flex;
    margin-top: 25px;
    padding: 0px 15px;
    flex-direction: row-reverse;
    @include susy-media($smartphone_small) {
      display: block;
    }

    .submit_button,
    .reset_button {
      .MuiButton-label {
        font-size: smaller;
        letter-spacing: 1px;
        font-weight: 600;
      }
      button {
        @include susy-media($smartphone_small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
