@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  background-color: rgba(54, 162, 235, 0.8);
  width: 100%;
  max-width: 600;
  color: white;
  :hover {
    border-radius: 5px;
  }
  @include susy-media($smartphone) {
    width: 100%;
  }
  .summary__value {
    font-size: xx-large;
    font-weight: bold;
  }
  .summary__label {
    font-size: medium;
    font-weight: 200;
  }
}
