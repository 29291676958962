@use "../../../../styles/variables" as vars;

.filterButton {
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  min-width: 250px;
  align-items: center;
}

.label {
  font-size: small;
  color: vars.$c-gray;
}

.icon {
  font-size: small !important;
}

.filtered {
  color: vars.$c-color-primary;
}
