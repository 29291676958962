.content {
}

.header {
  font-size: large;
  font-weight: 500;
  margin-bottom: 25px;
  h3 {
    margin: 0px;
  }
}
