@import "../../../../../../../../node_modules/susy/sass/susy";
@import "../../../../../../../styles/variables";

.admissionTypesForm {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .formInsertMode {
    margin: 0px 0px 20px;
  }

  .row {
    justify-content: space-between;
  }

  .admissionTypesForm__item {
    margin: 7px 0px;
    padding: 0px 15px;
    width: 50%;
    @include susy-media($narrow) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_land) {
      padding: 0px 10px;
    }
    @include susy-media($medium-up) {
      width: 25%;
    }
    @include susy-media($tablet_port) {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
    .textField,
    .selectField {
      width: 100%;
    }

    &.halfWidth {
      width: 50%;
      @include susy-media($smartphone) {
        width: 100%;
      }
    }
    &.fullWidth {
      width: 100%;
    }
  }

  .admissionTypesForm__buttonSet {
    display: flex;
    margin-top: 25px;
    padding: 0px 15px;
    flex-direction: row-reverse;
    @include susy-media($smartphone_small) {
      display: block;
    }

    .submit_button,
    .reset_button {
      .MuiButton-label {
        font-size: smaller;
        letter-spacing: 1px;
        font-weight: 600;
      }
      button {
        @include susy-media($smartphone_small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}
