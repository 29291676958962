$c-color-primary: #fc1812;
$c-color-secondary: #f8f4f4;

$c-white: #fff;
$c-black: #031833;
$c-gray: #444444;
$c-grey-medium: #808080;
$c-grey-light: #77869e;
$c-grey-lighter: #c0c0c0;
$c-dark: rgba(48, 49, 51);

$c-error-light: #ff8a65;

$c-color-info: #008000;
$c-green: #00ff00;
$c-red: #f00;

$c-info: #234479;
$c-warning: #896110;
$c-info-light: #c4d4ef;
$c-warning-light: #f7e3bb;

$smartphone_small: 320px 400px;
$smartphone_big: 401px 767px;
$phone: 320px 474px;
$watch: (
  max-width: 320px,
);
$medium: (
  max-width: 1280px,
);
$medium-up: (
  min-width: 768px,
);
$medium-down: (
  max-width: 767px,
);
$large: (
  min-width: 1025px,
);
$phablet: 475px 767px;

$smartphone: 320px 767px;
$tablet_land: (
  min-width: 768px,
  max-width: 1024px,
  orientation: landscape,
);
$tablet_port: (
  min-width: 768px,
  max-width: 1024px,
  orientation: portrait,
);
$narrow: 1025px 1200px;
$wide: 1201px 1440px;
$big: 1441px;
