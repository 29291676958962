@import "../../../styles/index";
@import "../../../../node_modules/susy/sass/susy";

.patientOperation {
  .info-box-container {
    margin-top: 24px;
  }

  .patientOperationTable {
    margin-top: 50px;
  }
  .printOperation__button {
    margin-top: 20px;
    button {
      width: 200px;
    }
  }

  .patientOperation__subtitle {
    display: flex;
    padding: 16px;
    width: "100%";
    font-size: large;
    font-weight: 400;
    border: 1px solid $c-color-primary;
    border-radius: 5px;
    & > .icon {
      width: 75px;
    }
  }
}
