@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.autocomplete {
  width: 100%;
  margin-top: 8px !important;
  label.MuiFormLabel-root {
    &.MuiFormLabel-filled,
    &.Mui-focused {
      top: 2px;
    }
  }

  .MuiFormHelperText-root.Mui-error{
    position: absolute;
    bottom: -20px;
    margin: 0px;
    left: 0px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
  }

  .MuiOutlinedInput-input {
    padding: 14px 14px;
  }
}

.autocomplete__light {
  width: 100%;
  margin-top: 8px !important;
  label {
    &.MuiFormLabel-filled,
    &.Mui-focused {
      top: 2px;
    }
  }
  input {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 70%;
    // font-size: small;
  }
  .MuiFormLabel-root {
    color: $c-white;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $c-white;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #eeeeee;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #eeeeee;
  }

  .MuiFormLabel-root.Mui-error {
    color: #eeeeee;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffcc80 !important;
  }

  .MuiTouchRipple-root {
    color: #eeeeee !important;
  }
  .MuiIconButton-root{
    color: white !important;
  }  

  .MuiInputBase-input {
    color: $c-white !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $c-gray inset !important;
    -webkit-box-shadow: 0 0 0 30px $c-gray inset !important;
    -webkit-text-fill-color: $c-white !important;
  }

  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: $c-error-light !important;
  }

  .MuiFormHelperText-root.Mui-error {
    color: $c-error-light !important;
  }
  .MuiAutocomplete-root fieldset:hover{
    border-color: #2196F3 !important;
  }

}

.patientSearch {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .patientSearchForm__item{
    margin: 4px 0px;
    padding: 0px 5px;
    width: 33%;
    @include susy-media($narrow) {
      padding: 0px 5px;
    }
    @include susy-media($tablet_land) {
      padding: 0px 5px;
    }
    @include susy-media($tablet_port) {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
    .dateField,
    .textField,
    .selectField {
      width: 100%;
    }

    &.fullWidth{
      width: 100%;
    }

    &.compressed{
      .textField {
        float: left;
        width: 50%;
        &:nth-of-type(1){
          padding-right: 10px;
        }
      }
    }
  }
  .submit_button {
    margin-top: 10px;  
    .MuiButton-label {
      padding: 5px;      
      font-size: smaller;
      letter-spacing: 1px;
      font-weight: 600;
    }
    button {
      width: 100%;
      @include susy-media($smartphone_small) {
        margin-top: 10px;
      }
    }
  }

  .patient_card {
    :hover {
      background-color: #f1f1f1;
    }
    .patient_card_content {
        @include susy-media($tablet_port) {
          display: flex;
          align-items: center;
    
        }
        @include susy-media($smartphone) {
          align-items: center;  
        }
      .attribute_item {
          display: flex;
          align-items: center;  
          white-space: nowrap;
          overflow: hidden;
        }
    }

    .imageIcon {
      transform: scale(0.5);
    }
  } 
  
  

}

.patientSearchResult .resultPagination {
  display: flex;
  justify-content: center;
}

