@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.custom__modal {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  @include susy-media($narrow) {
    width: 800px;
  }
  @include susy-media($tablet_land) {
    width: 600px;
  }
  @include susy-media($tablet_port) {
    width: 400px;
  }
  @include susy-media($smartphone) {
    width: 300px;
  }

  .MuiCardContent-root {
    padding: 10px;
  }
  .MuiDialogTitle-root {
    padding-left: 20px;
  }
  .MuiDialogContent-root {
    padding: 10px;
    max-height: 80vh;
  }
}
