@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.patientExamRequestForm {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 26px;

  h5 {
    margin: 8px auto;
  }

  .patientExamRequestForm__item {
    margin: 7px 0px;
    padding: 0px 15px;
    @include susy-media($narrow) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_land) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_port) {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
    .textField,
    .selectField {
      width: 100%;
    }

    &.fullWidth {
      width: 100%;
    }
    &.col-5 {
      width: 41.66%;
      @include susy-media($tablet_port) {
        width: 50%;
      }
      @include susy-media($smartphone) {
        width: 100%;
      }
    }

    &.col-2 {
      width: 16.66%;
      @include susy-media($tablet_port) {
        width: 50%;
      }
      @include susy-media($smartphone) {
        width: 100%;
      }
    }

    &.col-6 {
      width: 50%;
      @include susy-media($smartphone) {
        width: 100%;
      }
    }
  }

  .submit_button {
    margin-top: 8px;  
    .MuiButton-label {
      padding: 5px;      
      font-size: smaller;
      letter-spacing: 1px;
      font-weight: 600;
    }
    button {
      width: 100%;
      @include susy-media($smartphone_small) {
        margin-top: 0px;
        width: 100%;
      }
    }
  }

  .info-box-container {
    margin: auto;
    margin-top: 5px !important;
    width: 100%;
    line-break: anywhere;
    .infoBox h5{
      line-break: anywhere;
    }
  }
}
