@import "../../../../../styles/variables";
@import "../../../../../../node_modules/susy/sass/susy";

.preview {
  .preview__close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .preview__image {
    object-fit: contain;
    width: 100%;
  }
  .MuiDialogContent-root {
    max-width: 600px;
  }
}
