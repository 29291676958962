@import "../../../../../styles/variables";
@import "../../../../../../node_modules/susy/sass/susy";

.instances {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;

  & > .MuiButton-root {
    align-self: flex-start;
    padding-left: 0px;
    transform: translateX(-4px);
    text-transform: none;
  }

  .instances__actions {
    display: flex;
    justify-content: end;
  }
}
