@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.footer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  background-color: $c-gray;
  height: 120px;
  z-index: 10;
  @include susy-media($smartphone) {
    position: static;
    display: block;
    text-align: center;
    height: auto;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  .ISF__logo {
    height: 55px;
  }

  .footer__infoBox {
    display: flex;
    flex-direction: column;
    height: fit-content;
    @include susy-media($smartphone) {
      flex-direction: initial;
      margin-bottom: 25px;
      display: block;
      height: auto;
    }
  }

  .footer__infoBox__row {
    color: $c-white;
    font-size: small;
  }
  @media print {
    display: none;
  }
}
