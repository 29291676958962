@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

// .pie {
//   max-width: 300px;
//   max-height: 300px;
// }

canvas {
  height: unset !important;
  width: unset !important;
}
