.table{
  width: 100%;
  .table_header{
    th{
      text-transform: capitalize;
      font-weight: 600;
    }
  }
  .table_body{
    tr{
      td.MuiTableCell-root{
        padding: 14px;
      }
    }
  }
  .fullWidth {
      width: 100%;
    }
.fullWidth {
      width: 100%;
    }

}