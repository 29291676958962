@import "../../../styles/index";
@import "../../../../node_modules/susy/sass/susy";

.patientAdmission {
  .infoBox {
    width: 100%;
  }

  .patientAdmissionTable {
    margin-top: 50px;
  }
  .printAdmission__button {
    margin-top: 20px;
    button {
      width: 200px;
    }
  }

  .patientAdmission__subtitle {
    display: flex;
    padding: 16px;
    width: "100%";
    font-size: large;
    font-weight: 400;
    border: 1px solid $c-color-primary;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;

    & > .icon {
      width: 75px;
    }
  }
}
