@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.MuiFormGroup-root {
  flex-wrap: none !important;
  flex-direction: row !important;
  width: 320px;
}

.Mui-checked {
  color: $c-color-primary !important;
}

.checkboxes {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.patientOpdForm {
  display: inline-block;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .formInsertMode {
    margin: 0px 0px 20px;
  }

  .patientOpdForm__form {
    width: 100%;
  }

  .patientOpdForm__item {
    margin: 7px 0px;
    padding: 0px 15px;
    width: 33%;
    &.fullWidth {
      width: 100%;
    }
    @include susy-media($narrow) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_land) {
      padding: 0px 10px;
    }
    @include susy-media($tablet_port) {
      width: 50%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
    .dateField,
    .textField,
    .selectField {
      width: 100%;
    }

    &.fullWidth {
      width: 100%;
    }

    &.compressed {
      .textField {
        float: left;
        width: 50%;
        &:nth-of-type(1) {
          padding-right: 10px;
        }
      }
    }
  }

  details {
    border: 0px solid $c-grey-lighter;
    border-width: thin;
    border-radius: 4px;
    padding: 5px;
    .empty_operation_rows {
      color: $c-grey-lighter;
      display: table;
      margin: 0 auto;
    }
  }
  details > summary {
    margin-bottom: 2px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    .operation_icon {
      margin-right: 5px;
    }
  }
  .patientOpdForm__buttonSet {
    display: flex;
    margin-top: 25px;
    padding: 0px 15px;
    flex-direction: row-reverse;
    @include susy-media($smartphone_small) {
      display: block;
    }
    .visits_button {
      display: flex;
      flex-direction: row-reverse;
      @include susy-media($smartphone_small) {
        display: block;
      }
    }
    .submit_button,
    .reset_button .add_button {
      .MuiButton-label {
        font-size: smaller;
        letter-spacing: 1px;
        font-weight: 600;
      }
      button {
        @include susy-media($smartphone_small) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
}

.add_another_item {
  margin: 7px 0px;
  padding: 0px 15px;
}
