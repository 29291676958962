@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.studies {
  display: flex;
  flex-direction: column;

  .header {
    margin-top: 16px;
  }

  .studies__summary {
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
  }

  .studies__lastStudy {
    font-style: normal !important;
    font-size: 16px !important;
  }
  .infoBox {
    width: 100%;
  }
}
