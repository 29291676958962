@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";
@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.lab_labs {
  .lab_details {
    ul {
      white-space: nowrap;
      overflow-x: hidden;
      margin-left: 0;
      padding-left: 0;
    }
    li span .MuiTypography-h5 {
      padding-left: 0;
      margin-left: 0;
      display: inline;
      text-decoration: none;
      color: darken($c-gray, 25%);
    }
    .label {
      font-weight: bold;
    }
  }

  .lab__header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 25px;
    @include susy-media($smartphone) {
      .new__button__label {
        display: none;
      }
    }

    .lab__title {
      font-size: xx-large;
      color: $c-black;
      font-weight: bolder;

      @include susy-media($tablet_port) {
        font-size: x-large;
      }
      @include susy-media($smartphone) {
        font-size: x-large;
      }
      @include susy-media($smartphone_small) {
        font-size: x-large;
      }
    }
    .lab_actions {
      @include susy-media($smartphone_small) {
        display: block;
      }
      & {
        .MuiButton-label {
          font-size: smaller;
          letter-spacing: 1px;
          font-weight: 600;
        }
        button {
          @include susy-media($smartphone_small) {
            width: 100%;
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.labDetails {
  background-color: $c-color-secondary;

  .labDetails__content {
    flex: 3;
    display: flex;
    flex-direction: column;
    @include susy-media($smartphone) {
      display: block;
      flex: initial;
      border-radius: 10px;
      flex-direction: initial;
      background-color: $c-white;
      box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
    }
    .labDetails__content__wrapper {
      padding: 20px;
      margin-bottom: 15px;
      background-color: $c-white;
      box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
      .labDetails__content__header {
        position: relative;
        svg {
          position: absolute;
          top: 0px;
        }
        span {
          font-weight: 700;
          border-bottom: 1px solid #c9c9c9;
          padding-bottom: 5px;
          width: 100%;
          display: inline-block;
          padding-left: 25px;
        }
      }
      .labDetails__content__body {
        display: flex;
        flex-flow: row wrap;
        .labDetails__content__item {
          width: 25%;
          padding: 7px 20px;
          display: inline-block;
          .labDetails__content__item__label {
            font-weight: 400;
            font-size: 0.85rem;
          }
          .labDetails__content__item__value {
            font-weight: bolder;
            font-size: smaller;
          }
          @include susy-media($smartphone) {
            width: 100%;
          }
          @include susy-media($tablet_land) {
            width: 50%;
          }
          @include susy-media($tablet_port) {
            width: 50%;
          }
        }
        .labDetails__content__item_long_text {
          padding: 7px 20px;
          width: 100%;
        }
      }
    }
  }

  .footer {
    position: relative;
  }

  .active {
    background-color: lighten($c-gray, 10);
  }
}
