@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.logo {
  margin-top: 0px;
  margin-bottom: 15px;
}

.title {
  padding: 50px 25px 25px 25px;
  font-size: x-large;
  text-align: center;
  font-weight: bolder;
  @include susy-media($narrow) {
    padding: 25px;
  }
  @include susy-media($tablet_land) {
    padding: 25px;
  }
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);
  width: 100vw;
  background-color: $c-color-secondary;
  @include susy-media($smartphone) {
    padding: 50px 0 100px 0;
    height: 100%;
  }
}
