@import "../../../../styles/variables";

.menu {
  display: flex;
  flex-direction: column;
  padding: 8px 0px;
}

.label {
  margin: 4px 0px;
  padding-left: 24px;
  margin-top: 48px;
  color: $c-grey-medium;
}

.hospitalData {
  display: flex;
  flex-direction: column;
  padding: 12px 8px 8px 24px;
  gap: 8px;
}

.editButton {
  color: black !important;
  background-color: white !important;
  &:hover {
    background-color: gray !important;
  }
}

.item {
  display: flex;
  flex-direction: column;
  color: $c-white;
}

.labelSmall {
  font-size: medium;
}

.value {
  font-size: large;
  font-weight: 900;
}
