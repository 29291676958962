@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.patientDetails__content_header {
  font-size: large;
  font-weight: 500;
  margin-bottom: 25px;
  h3 {
    margin: 0px;
  }
}
