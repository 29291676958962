@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.series {
  display: flex;
  flex-direction: column;

  & > .MuiButton-root {
    align-self: flex-start;
    padding-left: 0px;
    transform: translateX(-4px);
    text-transform: none;
  }

  .series__actions {
    display: flex;
    justify-content: end;
  }

  .header {
    margin-top: 16px;
  }

  .series__studyTitle {
    font-style: normal !important;
    font-size: 20px !important;
  }
  .infoBox {
    width: 100%;
  }
}
