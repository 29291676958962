@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.patientDetails {
  background-color: $c-color-secondary;

  .container {
    @include susy-media($tablet_land) {
      width: 100%;
    }
    @include susy-media($tablet_port) {
      width: 100%;
    }
    @include susy-media($smartphone) {
      width: 100%;
    }
  }

  .patientDetails__background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .patientDetails__nested_content {
    padding: 30px;
    @include susy-media($smartphone) {
      padding: 30px 0;
    }
  }

  .patientDetails__title {
    font-size: xx-large;
    color: $c-black;
    font-weight: bolder;
    margin: 30px 20px;
  }

  .patientDetails__panel {
    display: flex;
    align-items: stretch;
    background-color: $c-white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
    margin: 20px 20px 40px 20px;
    overflow: hidden;
    @include susy-media($smartphone) {
      display: block;
      align-items: initial;
      background-color: transparent;
      margin: 40px 20px 80px 20px;
      border-radius: initial;
      box-shadow: initial;
    }
  }

  .patientDetails__personalData {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: $c-gray;
    padding: 0px 0px 40px 0px;

    &.open_sidebar {
      .patientDetails__personalData__trigger_mobile {
        svg {
          transform: rotate(180deg);
          top: 5px;
        }
      }
      .patientDetails__personalData_sidebar {
        max-height: 1200px;
        .patientDetails__personalData__item:last-of-type {
          // margin: 10px 30px 20px;
        }
      }
    }

    @include susy-media($smartphone) {
      display: block;
      flex: initial;
      padding: 0px;
      flex-direction: initial;
      border-radius: 10px;
      box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
    }
    @media print {
      display: none !important;
    }
  }

  .patientDetails__personalData_sidebar {
    position: relative;

    .patientDetails__personalData_edit_button_wrapper {
      padding: 0px 20px;
      margin-bottom: 20px;
      .patientDetails__personalData_edit_button {
        cursor: pointer;
        width: 100%;

        button {
          width: 100%;
        }

        svg {
          font-size: 0.925rem;
          margin-right: 7px;
        }

        span {
          color: $c-white;
          font-size: 0.7rem;
        }
      }
    }

    .patientDetails__main_menu {
      margin-bottom: 40px;

      h6 {
        color: $c-grey-medium;
        position: relative;
        left: 20px;
        margin: 0px 0px 10px;
      }

      .patientDetails__main_menu__item {
        color: $c-grey-lighter;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        padding: 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: lighten($c-gray, 10);
        }

        svg {
          position: relative;
          margin-right: 5px;
          font-size: 1rem;
          top: 3px;
        }

        .icon_toggle {
          position: absolute;
          top: calc(50% - 10px);
          right: 15px;
          width: 20px;
          transform: rotate(-90deg);
        }

        & + .patientDetails__main_menu__item {
          border-top: 1px solid $c-grey-medium;
        }
      }
    }

    .patientDetails__user_info {
      h6 {
        color: $c-grey-medium;
        position: relative;
        left: 20px;
        margin: 0px;
      }
    }

    .accordion {
      .accordion_summary {
        padding: 20px;
        color: $c-grey-lighter;
        font-weight: bold;

        svg {
          position: relative;
          margin-right: 5px;
          font-size: 1rem;
          top: 3px;
        }
      }

      .accordion_details {
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: darken($c-gray, 25%);
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: darken($c-gray, 10%);
        }
      }
      & + .accordion {
        border-top: 1px solid $c-grey-medium;
      }
    }

    @include susy-media($smartphone) {
      overflow: hidden;
      max-height: 0px;
      transition: 0.5s max-height ease-in-out;
    }
  }

  .patientDetails__personalData__trigger_mobile {
    display: none;
    color: $c-white;
    position: relative;
    padding: 15px 40px 15px 15px;
    @include susy-media($smartphone) {
      display: block;
    }
    svg.arrow_icon {
      position: absolute;
      top: 12px;
      width: 30px;
      right: 10px;
      fill: $c-white;
      transition: 0.3s transform ease-in-out;
    }
  }

  .patientDetails__profilePictureContainer_wrapper {
    display: flex;
    padding: 20px;
    margin: 20px;
    align-items: center;
    border-radius: 10px;
    background-color: lighten($c-gray, 10%);
  }

  .patientDetails__profilePictureContainer {
    width: 60px;
    height: 60px;
    display: inline-block;
    .profilePicture_mask {
      width: 60px;
      height: 60px;
    }
  }

  .patientDetails__header__info {
    margin-left: 12px;
    .patientDetails__header__info__item {
      color: $c-white;
      display: block;
      text-transform: uppercase;
    }
  }

  .patientDetails_status {
    margin: 20px;
    .patientDetails_status_wrapper {
      display: flex;
      color: $c-white;
      span:before {
        content: "";
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 3px;
        margin-left: 3px;
      }

      &.patientDetails_status_in {
        span:before {
          background-color: $c-red;
          animation: bump 3s infinite;
        }
      }

      &.patientDetails_status_out {
        span:before {
          background-color: $c-green;
        }
      }
      h6 {
        margin: 0px;
      }
      .patientDetails_status_button {
        border-bottom: 1px solid transparent;
        display: inline-block;
        cursor: pointer;
        transition: 0.3s;
        margin-left: 3px;
        &:hover {
          border-bottom: 1px solid $c-white;
        }
      }
    }
  }

  @keyframes bump {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .patientDetails__personalData__item {
    width: 100%;
    padding: 7px 20px;
    color: $c-white;
    display: inline-block;

    .patientDetails__personalData__item__label {
      font-size: 0.85rem;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    &.longText {
      .patientDetails__personalData__item__label {
        font-weight: bold;
        margin-bottom: 10px;
      }
      .patientDetails__personalData__item__value {
        font-weight: initial;
        font-size: smaller;
      }
    }
  }

  .patientDetails__personalData__item__value {
    font-weight: bold;
  }

  .patientDetails__content {
    flex: 3;
    display: flex;
    flex-direction: column;
    @include susy-media($smartphone) {
      display: block;
      flex: initial;
      margin-top: 35px;
      border-radius: 10px;
      padding: 40px 25px 25px;
      flex-direction: initial;
      background-color: $c-white;
      box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
    }
    .patientDetails__personalData {
      display: none !important;
      flex-flow: column wrap;
      background-color: transparent;
      box-shadow: 2px 1px 5px $c-grey-lighter;
      padding: 8px;
      margin: 24px;
      border-radius: 5px;
      color: inherit;
      height: 200px;
      .patientDetails__personalData__item {
        width: 150px;
        color: $c-gray;
      }
      @media print {
        display: flex !important;
      }
    }
    .patientDetails__profilePictureContainer_wrapper {
      display: none;
      padding: 20px;
      margin: 20px;
      align-items: center;
      border-radius: 10px;
      background-color: lighten($c-gray, 10%);
      @media print {
        display: flex;
      }
    }

    .patientDetails__profilePictureContainer {
      width: 60px;
      height: 60px;
      display: inline-block;
      .profilePicture_mask {
        width: 60px;
        height: 60px;
      }
    }

    .patientDetails__header__info {
      margin-left: 12px;
      .patientDetails__header__info__item {
        color: $c-white;
        display: block;
        text-transform: uppercase;
      }
    }
  }

  .patientDetils__content__header {
    background-color: #f5f5f5;
  }

  .footer {
    position: relative;
  }

  .active {
    background-color: lighten($c-gray, 10);
  }
}
