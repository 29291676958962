@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";
.header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  .headerActions {
    display: flex;
    justify-content: end;
    flex: 1;
    flex-wrap: wrap;
    gap: 4px;
  }
  margin-bottom: 12px;
}

.table {
  @media print {
    height: auto !important;
  }
  .headerCell {
    display: flex;
    align-items: center;
  }
  .table_header {
    th {
      text-transform: capitalize;
      font-weight: 600;
    }
  }
  .table_body {
    @media print {
      height: auto !important;
    }
    tr {
      td.MuiTableCell-root {
        padding: 5px;
      }
    }
    .collapseWrapper {
      background: lighten($c-grey-lighter, 22%);
      padding: 5px 5px;
      flex: 1;
      ul {
        column-count: 2;
        margin: 14px;
        padding: 15px 27px;
        @include susy-media($smartphone) {
          column-count: 1;
        }
        li {
          list-style: none;
        }
        li + li {
          margin-top: 5px;
        }
      }
    }
    .collapseItem_row {
      word-break: break-all;
      display: flex;
    }
  }
}
