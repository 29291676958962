@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.admin {
  display: flex;
  flex-direction: column;
  background-color: $c-color-secondary;
}

.content {
  display: flex;
  background-color: $c-white;
  box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);
  border-radius: 8px;
  flex-grow: 1;
  margin: 30px 160px;
  @include susy-media($medium) {
    margin: 20px 20px;
  }
  @include susy-media($medium-down) {
    flex-direction: column;
    margin: 20px 20px;
  }
  width: -webkit-fill-available;
}

.menuButton {
  padding: 24px;
  color: $c-white;
  font-size: x-large;
  font-weight: bold;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: $c-gray;
  border-radius: 8px 0px 0px 8px;
  width: max-content;
  padding: 8px 0px;
  min-width: 311px;
  @include susy-media($medium-down) {
    border-radius: 8px;
    width: 100%;
    padding: 0px;
  }
}

.outlet {
  padding: 24px;
  border-radius: 8px;
  flex-grow: 1;
}
