@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.selectTypeControl {
  margin: 7px 0px;
  padding: 0px 2px;
  width: 50%;
  &.fullWidth {
    width: 100%;
  }
  @include susy-media($narrow) {
    padding: 0px 10px;
  }
  @include susy-media($tablet_land) {
    padding: 0px 10px;
  }
  @include susy-media($tablet_port) {
    width: 50%;
  }
  @include susy-media($smartphone) {
    width: 100%;
  }
}

.typeContent {
  margin-top: 60px;
}
