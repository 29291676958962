@import "../../../../../styles/variables";
@import "../../../../../../node_modules/susy/sass/susy";

.filter {
  display: flex;
  flex-direction: column;
  .filter__header {
    display: flex;
    justify-content: end;
    .MuiFormGroup-root {
      justify-content: end;
    }
    .MuiFormControlLabel-label {
      font-size: 0.95rem;
      color: lighten($color: $c-gray, $amount: 30);
    }
  }
  .filter__main {
    display: flex;
    gap: 16px 16px;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 1px lighten($color: $c-gray, $amount: 10);
    column-gap: 8px;
    .options {
      button {
        padding: 5px 15px;
        & button {
          padding: 0px 0px 0px 10px;
        }
      }
    }
  }
  .filter__actions {
    display: flex;
    column-gap: 4px;
    align-items: center;
    justify-content: left;
    & > span {
      display: flex;
      font-size: large;
      color: lighten($color: $c-gray, $amount: 1);
      text-transform: uppercase;
      justify-self: end;
      flex-grow: 1;
      justify-content: end;
    }
  }
  filter__datefield {
    display: flex;
    gap: 4px;
    @include susy-media($smartphone) {
      flex-direction: column;
    }
  }
}
