@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.patientTriage{
  .patientTriageTable{
    margin-top: 50px;
  }
  .printTriage__button{
    margin-top: 20px;
    button{
      width: 200px;
    }
  }
}