@import "../../../../styles/variables";
@import "../../../../../node_modules/susy/sass/susy";

.filterLabForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .MuiAccordion-root {
    width: 100%;
  }
  .MuiAccordionSummary-content {
    svg {
      position: relative;
      top: -2px;
    }
    h5 {
      margin: 0px 10px;
    }
  }
  .filterLabForm__form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 8px;
    .filterLabForm__section {
      display: flex;
      flex-direction: column;
      width: 100%;
      .filterLabForm__section_title {
        text-transform: uppercase;
        color: $c-grey-lighter;
        font-size: medium;
      }
      .filterLabForm__section_content {
        display: flex;
        flex-flow: wrap row;
        justify-content: start;
        .filterLabForm__item {
          margin-right: 5px;
          margin-bottom: 15px;
          align-items: center;
          width: 32.8%;
          .dateField,
          .dateField__light,
          .textField,
          .selectField,
          .autocompleteField {
            width: 100%;
          }
          @include susy-media($tablet_land) {
            width: 32.5%;
          }
          @include susy-media($smartphone) {
            width: 48.5%;
          }
          @include susy-media($phone) {
            width: 100%;
          }
          @include susy-media($watch) {
            width: 100%;
          }
          &.col-3 {
            width: 24.4%;
            @include susy-media($tablet_land) {
              width: 32.5%;
            }
            @include susy-media($smartphone) {
              width: 48.5%;
            }
            @include susy-media($phone) {
              width: 100%;
            }
            @include susy-media($watch) {
              width: 100%;
            }
          }
        }
      }
    }
    .filterForm__buttonSet {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      justify-content: flex-end;

      .MuiButton-label {
        font-size: smaller;
        letter-spacing: 1px;
        font-weight: 600;
      }
      .MuiButton-root {
        width: 150px;
        @include susy-media($phone) {
          width: 100%;
        }
        @include susy-media($watch) {
          width: 100%;
        }
      }
    }
  }
  @media print {
    display: none !important;
  }
}
