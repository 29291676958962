@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.dateRangeField {
  display: flex;
  gap: 4px;
  align-items: center;

  @include susy-media($smartphone) {
    flex-direction: column;
  }
}
