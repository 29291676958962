@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.dialog__title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.dialog__icon {
  height: 90px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dialog__info {
  margin: 25px 0px 40px;
}

.dialog__divider {
  width: 100%;
  background-color: $c-grey-light;
  height: 0.5px;
}

.dialog__buttonSet {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
  gap: 2px;
  @include susy-media($smartphone_small) {
    display: block;
  }

  .submit_button,
  .reset_button {
    .MuiButton-label {
      font-size: smaller;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
}
