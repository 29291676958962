@import "../../../styles/variables";
@import "../../../../node_modules/susy/sass/susy";

.notFound {
  width: 100%;
  .notFound__background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 120px);
    width: 100vw;
    background-color: $c-color-secondary;
    @include susy-media($smartphone) {
      padding: 50px 0 100px 0;
      height: 100%;
    }
  }

  .notFound__logo {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .notFound__title {
    padding: 50px 25px 25px 25px;
    font-size: x-large;
    text-align: center;
    font-weight: bolder;
    @include susy-media($narrow) {
      padding: 25px;
    }
    @include susy-media($tablet_land) {
      padding: 25px;
    }
  }
  .notFound__panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background-color: $c-white;
    border-radius: 10px;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(48, 49, 51, 0.1);

    .notFound__buttonContainer {
      margin-top: 5px;
    }

    .notFound__panel__resetPassword {
      color: $c-black;
      margin-top: 10px;
    }
  }
}
