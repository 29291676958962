@import "./flexboxgrid";
@import "./variables";
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.App {
  & > div {
    min-height: 100vh;
  }

  [class*="Form"],
  .tabs_header,
  .MuiIconButton-root,
  .MuiButton-root,
  .MuiSvgIcon-root {
    @media print {
      display: none !important;
    }
  }

  .info-box-container {
    margin: 15px 0px;
  }
}
